<template>
    <div class="account_user">

        <div class="page-header">
            <el-row>
                <el-col :span="24" align="right">
                    <el-button type="primary" round>Add User</el-button>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                        prop="id"
                        label="ID"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="user_name"
                        label="User Name">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="Name">
                        </el-table-column>
                        <el-table-column
                        prop="role"
                        label="Role">
                        </el-table-column>
                        <el-table-column
                        prop="branch_name"
                        label="Branch Name">
                        </el-table-column>
                        <el-table-column label="Action" align="right">
                            <template #default="scope">
                                <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
                                <el-button
                                size="mini"
                                type="primary"
                                @click="handleClick(scope.$index, scope.row)">View</el-button>
                                <el-popconfirm
                                @confirm="handleDelete(scope.row, scope.$index)"
                                title="確定要刪除嗎？">
                                    <template #reference>
                                        <el-button type="danger" size="small">Delete</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Edit User Info" v-model="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="ID" :label-width="formLabelWidth">
                    <el-input v-model="form.id" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="Username" :label-width="formLabelWidth">
                    <el-input v-model="form.user_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Name" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Icon" :label-width="formLabelWidth">
                    <el-upload
                        action=""
                        list-type="picture-card"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :multiple="1">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-input v-model="form.school" autocomplete="off">
                        <template #append>
                            <el-button style="border-right: 1px solid #DCDFE6;">Cancel</el-button>
                            <el-button>Browse</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="Password" :label-width="formLabelWidth">
                    <el-input v-model="form.password" autocomplete="off" show-password></el-input>
                </el-form-item>
                <el-form-item label="Name" :label-width="formLabelWidth">
                    <el-input v-model="form.confirm_password" autocomplete="off" show-password></el-input>
                </el-form-item>
                <el-form-item label="Role" :label-width="formLabelWidth">
                    <el-select v-model="form.role" placeholder="Select">
                        <el-option label="Manager " value="Manager"></el-option> 
                        <el-option label="Officer" value="Officer"></el-option>
                        <el-option label="SuperAdmin" value="SuperAdmin"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Branch Name" :label-width="formLabelWidth">
                    <el-input v-model="form.branch_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Create Time" :label-width="formLabelWidth">
                    <p>{{ form.create_time }}</p>
                </el-form-item>
                <el-form-item label="Update Time" :label-width="formLabelWidth">
                    <p>{{ form.update_time }}</p>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">Save</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
  export default {
      data() {
        return {
          tableData: [{
            id: '01',
            user_name: 'Lorem',
            name: 'Lorem ipsum',
            role: 'Officer',
            branch_name: 'Lorem ipsum'
          }, {
            id: '02',
            user_name: 'Lorem',
            name: 'Lorem ipsum',
            role: 'SuperAdmin',
            branch_name: 'Lorem ipsum'
          }, {
            id: '03',
            user_name: 'Lorem',
            name: 'Lorem ipsum',
            role: 'Manager',
            branch_name: 'Lorem ipsum'
          }, {
            id: '04',
            user_name: 'Lorem',
            name: 'Lorem ipsum',
            role: 'Officer',
            branch_name: 'Lorem ipsum'
          }],
          dialogFormVisible: false,
          formLabelWidth: '120px',
          form: {
            id: '1',
            user_name: 'ABCschool-NT',
            name: 'ABCschool NT branch',
            icon: '',
            school: 'ABCschool NT branch',
            password: 'abcabc123',
            confirm_password: 'abcabc123',
            role: 'Manager',
            branch_name: 'ABCschool NT branch',
            create_time: 'xx/xx/xxxx xx:xx',
            update_time: 'xx/xx/xxxx xx:xx'
          },
          fileList: [
              {
                  name: 'test-image',
                  url: '/image/test-image.jpg'
              }
          ]
        }
      },
      methods: {
        handleEdit(index, row) {
            this.dialogFormVisible = true;
            console.log(index, row);
        },
        handleClick(index, row){
            console.log(index, row);
        },
        handleDelete(row, index) {
            ElMessage.success({
                message: '刪除成功',
                type: 'success'
            });
            this.tableData.splice(index, 1)
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
      }
    }
</script>